import React, { useEffect, useState } from 'react';
import Profile_Loading from '../../../assets/img/icons/Profile_Loading.svg'
import { firebase, database } from '../../../xFireBase/Storage';
import useMediaQuery from "@mui/material/useMediaQuery";
import { toast } from 'react-toastify';
import { Button } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../../xFireBase/AuthContext';

import { TextField } from '@mui/material';
import { Formik } from 'formik';
import * as yup from "yup";
import EditWDNHistory from './Edit_WDN_History';
import { Link } from 'react-router-dom';


const AccountEdit = () => {

  const { CurrentLanguage, darkMode } = useAuth();

  const isNonMobile = useMediaQuery("(min-width:992px)");

  const [User_OldData, setUser_OldData] = useState(null);
  const [User_Password_Database, setUser_Password_Database] = useState(null);
  const [User_FirstName, setUser_FirstName] = useState(null);
  const [User_LastName, setUser_LastName] = useState(null);

  const [ChangePassword_Loading, setChangePassword_Loading] = useState(false);

  const [box_isHovered, setBox_isHovered] = useState(false);


  useEffect(() => {
    database.collection('users').doc(firebase.auth().currentUser.email).get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setUser_OldData(data);
        setUser_FirstName(data.User_FirstName);
        setUser_LastName(data.User_LastName);
        setUser_Password_Database(data.User_Password);

      }
    })
  }, [])

  const Submit_Activate_A2G_promo = async (values) => {
    try {
      const user = firebase.auth().currentUser;
      const ActivateCode = values.ActivateCode;
      database.collection('users').doc('@important').collection('RETOpromo').doc(ActivateCode).get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          // check if the code is valid, and if it in the database
          if (data.code === ActivateCode && data.used === false) {
            // update the code to be used
            database.collection('users').doc('@important').collection('RETOpromo').doc(ActivateCode).update({
              used: true,
              used_date: firebase.firestore.Timestamp.fromDate(new Date()),
              expire_date: firebase.firestore.Timestamp.fromDate(new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)),
              used_by: user.email,
              used_by_uid: user.uid,
            })
              .then(() => {
                // update the user data
                database.collection('users').doc(user.email).update({
                  User_RETOpromo: true,
                  // update the activation date
                  User_RETOpromo_activationDate: firebase.firestore.Timestamp.fromDate(new Date()),
                  // update the expire date for another 30 days
                  User_RETOpromo_expireDate: firebase.firestore.Timestamp.fromDate(new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)),
                  // update the code to be used
                  User_RETOpromo_Currentcode: ActivateCode,
                })
                // notify the user that the code is valid
                const notify = () => toast.success(`تم التفعيل بنجاح ! ${values.ActivateCode}`);
                notify();
              })
          }
          else if (data.code === ActivateCode && data.used === true) {
            // notify the user that the code is used
            const notify = () => toast.error(`كود التفعيل مستخدم بالفعل ! ${values.ActivateCode}`);
            notify();
          }
          else if (data.code !== ActivateCode) {
            // notify the user that the code is invalid
            const notify = () => toast.error(`كود التفعيل غير صحيح ! ${values.ActivateCode}`);
            notify();
          }
        }
        else if (!doc.exists) {
          // notify the user that the code is invalid
          const notify = () => toast.error(` حاول مره ثانية ! `);
          notify();
        }
      })

    } catch (error) {
      // notify the user that the link has been copied
      const notify = () => toast.error(`حدث خطأ ما ! ${error}`);
      notify();
    }
  }

  const Submit_ChangeEmail = async (values) => {
    const user = firebase.auth().currentUser;
    const TheNewEmail = values.NewEmail;
    const TheCurrentPassword_ChangeEmail = values.TheCurrentPassword_ChangeEmail;
    const userCreditentials = firebase.auth.EmailAuthProvider.credential(user.email, TheCurrentPassword_ChangeEmail);

    if (TheNewEmail === user.email) {
      // notify the user that the email is the same as the old one
      const notify = () => toast.error(`البريد الإلكتروني مطابق للقديم !`);
      notify();
    } else if (TheCurrentPassword_ChangeEmail !== User_Password_Database) {
      // check if the password is wrong
      // notify the user that the password is wrong
      const notify = () => toast.error(`كلمة المرور غير صحيحة !`);
      notify();
    }
    else if (TheNewEmail !== user.email && TheCurrentPassword_ChangeEmail === User_Password_Database) {

      user.reauthenticateWithCredential(userCreditentials).then(() => {
        database.collection('users').doc(TheNewEmail).set(User_OldData).then(() => {
          database.collection('users').doc(user.email).delete().then(() => {
            user.updateEmail(TheNewEmail).then(() => {
              database.collection('users').doc(TheNewEmail).update({
                User_Email: TheNewEmail,
                User_EmailVerified: false,
                User_Profile_Picture: User_OldData?.User_Profile_Picture ? User_OldData.User_Profile_Picture : '',
              })
              // notify the user that the email has been changed
              const notify = () => toast.success(`تم تغيير البريد الإلكتروني بنجاح ! سيتم تحديث الصفحة في 5 ثواني`);
              notify();
              user.sendEmailVerification()
            })
            setTimeout(() => {
              window.location.reload();
            }, 2300);
          })
            .catch((error) => {
              // notify the user that the email is already used
              const errorMessage =
                error.code === "auth/email-already-in-use"
                  ? "البريد الإلكتروني مستخدم بالفعل"
                  : error.code === "auth/invalid-email"
                    ? "البريد الإلكتروني غير صحيح"
                    : "حدث خطأ ما";
              const notify = () => toast.error(`${errorMessage} !`);
              notify();
            });
        })
      })
    }
  }

  const Submit_Account_Details = async (values) => {
    const user = firebase.auth().currentUser;
    const FirstName = values.FirstName;
    const LastName = values.LastName;

    if (FirstName !== User_FirstName || LastName !== User_LastName) {
      if (FirstName !== User_FirstName) {
        database.collection('users').doc(user.email).update({
          User_FirstName: FirstName,
        })
      }
      if (LastName !== User_LastName) {
        database.collection('users').doc(user.email).update({
          User_LastName: LastName,
        })
      }
      // notify the user that the email has been changed
      const notify = () => toast.success(`تم تغيير الاسم بنجاح ! سيتم تحديث الصفحة في 5 ثواني`);
      notify();
      setTimeout(() => {
        window.location.reload();
      }, 2300);
    }
    else {
      // notify the user that the email is the same as the old one
      const notify = () => toast.error(`الاسم مطابق للقديم !`);
      notify();
    }
  }


  const Submit_ChangePassword = async (values) => {
    const user = firebase.auth().currentUser;
    const TheCurrentPassword = values.TheCurrentPassword_ChangePassword;
    const TheNewPassword = values.TheNewPassword_ChangePassword;
    const TheNewPassword_Confirm = values.TheNewPassword_Confirm_ChangePassword;
    const userCreditentials = firebase.auth.EmailAuthProvider.credential(user.email, TheCurrentPassword);

    setChangePassword_Loading(true)

    if (TheCurrentPassword !== User_Password_Database) {
      // check if the password is wrong
      // notify the user that the password is wrong
      const notify = () => toast.error(`كلمة المرور الحالية غير صحيحة !`);
      notify();
      setTimeout(() => {
        setChangePassword_Loading(false);
      }, 200);
    }
    else if (TheNewPassword !== TheNewPassword_Confirm) {
      // check if the password is wrong
      // notify the user that the password is wrong
      const notify = () => toast.error(`كلمة المرور الجديدة غير متطابقة !`);
      notify();
      setTimeout(() => {
        setChangePassword_Loading(false);
      }, 200);
    }
    else if (TheCurrentPassword === TheNewPassword || TheCurrentPassword === TheNewPassword_Confirm) {
      // check if the password is wrong
      // notify the user that the password is wrong
      const notify = () => toast.error(`كلمة المرور الجديدة مطابقة للقديمة !`);
      notify();
      setTimeout(() => {
        setChangePassword_Loading(false);
      }, 200);
    }
    else if (TheCurrentPassword !== TheNewPassword && TheCurrentPassword !== TheNewPassword_Confirm && TheNewPassword === TheNewPassword_Confirm
      && TheCurrentPassword === User_Password_Database) {
      user.reauthenticateWithCredential(userCreditentials).then(() => {
        database.collection('users').doc(user.email).update({
          User_Password: TheNewPassword,
        })
        user.updatePassword(TheNewPassword).then(() => {
          // notify the user that the password has been changed
          const notify = () => toast.success(`تم تغيير كلمة المرور بنجاح !`);
          notify();
        })
      }
      )
      setTimeout(() => {
        setChangePassword_Loading(false);
      }, 1500);
    }
  }

  const Activate_A2G_promo_initialValues = {
    ActivateCode: "",
  };
  const Activate_A2G_promo_Schema = yup.object().shape({
    ActivateCode: yup.string().required("الرجاء إدخال كود التفعيل"),

  });

  const ChangeEmail_initialValues = {
    NewEmail: "",
    TheCurrentPassword_ChangeEmail: "",
  };
  const ChangeEmail_Schema = yup.object().shape({
    NewEmail: yup.string().required("الرجاء إدخال البريد الإلكتروني"),
    TheCurrentPassword_ChangeEmail: yup.string().required("الرجاء إدخال كلمة المرور الحالية"),

  });

  const Account_Details_initialValues = {
    FirstName: "",
    LastName: "",
  };
  // check if the FirstName and LastName are  contain letters and numbers and spaces or just spaces only ?
  const Account_Details_Schema = yup.object().shape({
    FirstName: yup.string(),
    LastName: yup.string()
  });

  const ChangePassword_initialValues = {
    TheCurrentPassword_ChangePassword: "",
    TheNewPassword_ChangePassword: "",
    TheNewPassword_Confirm_ChangePassword: "",
  };

  const ChangePassword_Schema = yup.object().shape({
    TheCurrentPassword_ChangePassword: yup.string().required("الرجاء إدخال كلمة المرور الحالية"),
    TheNewPassword_ChangePassword: yup.string().required("الرجاء إدخال كلمة المرور الجديدة"),
    TheNewPassword_Confirm_ChangePassword: yup.string().required("الرجاء إدخال تأكيد كلمة المرور الجديدة"),
  });

  return (
    <div id='AccountEdit'>
      <div
        style={{
          textDecoration: 'none', color: 'white', direction: 'rtl', alignItems: 'flex-start', justifyContent: 'flex-end', textAlign: 'right', margin: '0.5rem',
          display: 'flex', flexDirection: isNonMobile ? 'row' : 'column', width: '100%',
        }}
      >


        {/** the box on the left */}
        <div className='container'>
          <div
            size={12}
            xs={6}
            sm={6}
            md={6}
            lg={4}
            xl={3}
            className={isNonMobile ? 'AccountEdit-bx' : 'AccountEdit-bx-Mobile'}
            style={{
              boxShadow: box_isHovered === true ? '0 0 20px 0 #42297c'
                : darkMode === 'light'
                  ? '0 0 20px 0 #14141488'
                  : '0 0 20px 0 #e2dada1a'
            }}
            onMouseEnter={() => setBox_isHovered(true)}
            onMouseLeave={() => setBox_isHovered(false)}
          >


            {/** the 2nd row */}

            <div className='AccountEdit-bx-Container-content'>
              <header style={{
                paddingRight: '30px',
                paddingTop: '50px', display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem'
              }}
              >
                <h2 style={{
                  fontSize: '1.25rem',
                  color: darkMode === 'light' ? '#000000' : 'white',
                  borderRadius: '0.2rem'
                }}
                >
                  <span style={{ color: '#ff434c' }}>|</span>
                  تغيير البريد الإلكتروني
                </h2>
              </header>
              <div style={{
                backgroundColor: darkMode === 'light' ? '#FFFFFF62' : '#FFFFFF0C',
                borderRadius: '15px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
                padding: '0.5rem',
              }}>
                <Formik onSubmit={Submit_ChangeEmail}
                  initialValues={ChangeEmail_initialValues}
                  validationSchema={ChangeEmail_Schema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} style={{ width: isNonMobile ? '55%' : '100%', flexDirection: 'column' }}>
                      <TextField
                        className='AccountEdit-Form-Textarea'
                        placeholder="البريد الإلكتروني الجديد"
                        fullWidth
                        name='NewEmail'
                        value={values.NewEmail}
                        onBlur={handleBlur('NewEmail')}
                        onChange={handleChange('NewEmail')}
                        variant="outlined"
                        inputProps={{ style: { color: 'white' } }}
                        InputLabelProps={{ style: { color: '#9FA1A3' } }}
                        style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr', margin: '10px 0px' }}
                        size="small"
                      />
                      <TextField
                        className='AccountEdit-Form-Textarea'
                        label="كلمة المرور الحالية"
                        fullWidth
                        name='TheCurrentPassword_ChangeEmail'
                        value={values.TheCurrentPassword_ChangeEmail}
                        onBlur={handleBlur('TheCurrentPassword_ChangeEmail')}
                        onChange={handleChange('TheCurrentPassword_ChangeEmail')}
                        variant="outlined"
                        inputProps={{ style: { color: 'white' } }}
                        InputLabelProps={{ style: { color: '#9FA1A3' } }}
                        style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr' }}
                        size="small"
                      />

                      <Button className='AccountEdit-Form-Button' type='submit'
                        style={{ width: isNonMobile ? '25%' : '80%', color: 'white', borderRadius: '30px', marginTop: '10px' }}
                        disabled={values.NewEmail.length > 0 && values.TheCurrentPassword_ChangeEmail.length > 0 ? false : true}>حفظ التغييرات</Button>
                    </form>
                  )}

                </Formik>
              </div>

            </div>

            {/** the 3rd row */}

            <div className='AccountEdit-bx-Container'>

              <div className='AccountEdit-bx-Container-content'>
                <header style={{ paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
                  <h2 style={{
                    fontSize: '1.25rem',
                    color: darkMode === 'light' ? '#000000' : 'white',
                    borderRadius: '0.2rem'
                  }}
                  >
                    <span style={{ color: '#ff434c' }}>|</span>
                    بيانات الحساب
                  </h2>
                </header>
                <span
                  aria-multiline="true"
                  style={{
                    fontSize: '0.95rem',
                    color: darkMode === 'light' ? '#000000' : '#AFAEAE',
                    borderRadius: '0.2rem',
                    width: isNonMobile ? '55%' : '65%'
                  }}
                >
                  لتغيير صورة الشخصية, الرجاء الضغط على صورتك بأعلى الصفحة.
                </span>
                <div style={{
                  backgroundColor: darkMode === 'light' ? '#FFFFFF62' : '#FFFFFF0C',
                  borderRadius: '15px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%',
                  padding: '0.5rem',
                }}>
                  <Formik onSubmit={Submit_Account_Details}
                    initialValues={Account_Details_initialValues}
                    validationSchema={Account_Details_Schema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit} style={{ width: isNonMobile ? '55%' : '100%', flexDirection: 'column' }}>
                        <TextField
                          className='AccountEdit-Form-Textarea'
                          label="الاسم الأول"
                          fullWidth
                          name='FirstName'
                          value={values.FirstName}
                          onBlur={handleBlur('FirstName')}
                          onChange={handleChange('FirstName')}
                          variant="outlined"
                          error={errors.FirstName && touched.FirstName}
                          helperText={errors.FirstName && touched.FirstName ? errors.FirstName : null}
                          inputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: '#9FA1A3' } }}
                          style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr', margin: '10px 0px' }}
                          size="small"
                        />
                        <TextField
                          className='AccountEdit-Form-Textarea'
                          label="الاسم الأخير"
                          fullWidth
                          name='LastName'
                          value={values.LastName}
                          onBlur={handleBlur('LastName')}
                          onChange={handleChange('LastName')}
                          variant="outlined"
                          inputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: '#9FA1A3' } }}
                          style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr', }}
                          size="small"
                        />
                        <Button className='AccountEdit-Form-Button' type='submit'
                          style={{ width: isNonMobile ? '40%' : '80%', color: '#ffffff', borderRadius: '30px', marginTop: '10px' }}
                          disabled={values.FirstName.length > 0 && values.LastName.length > 0 ? false : true}>حفظ التغييرات</Button>
                      </form>
                    )}

                  </Formik>
                </div>

              </div>

              <div className='AccountEdit-bx-Container-content'>
                <header style={{ paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
                  <h2 style={{
                    fontSize: '1.25rem',
                    color: darkMode === 'light' ? '#000000' : '#ffffff',
                    borderRadius: '0.2rem'
                  }}
                  >
                    <span style={{ color: '#ff434c' }}>|</span>
                    تغيير كلمة المرور
                  </h2>
                </header>
                <div style={{
                  backgroundColor: darkMode === 'light' ? '#FFFFFF62' : '#FFFFFF0C',
                  borderRadius: '15px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'right',
                  alignItems: 'center',
                  textAlign: 'center',
                  width: '100%',
                  padding: '0.5rem',
                }}>
                  <Formik onSubmit={Submit_ChangePassword}
                    initialValues={ChangePassword_initialValues}
                    validationSchema={ChangePassword_Schema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit} style={{ width: isNonMobile ? '55%' : '100%', flexDirection: 'column' }}>
                        <TextField
                          className='AccountEdit-Form-Textarea'
                          label="كلمة المرور الحالية"
                          fullWidth
                          name='TheCurrentPassword_ChangePassword'
                          value={values.TheCurrentPassword_ChangePassword}
                          onBlur={handleBlur('TheCurrentPassword_ChangePassword')}
                          onChange={handleChange('TheCurrentPassword_ChangePassword')}
                          variant="outlined"
                          inputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: '#9FA1A3' } }}
                          style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr', margin: '10px 0px' }}
                          size="small"
                        />
                        <TextField
                          className='AccountEdit-Form-Textarea'
                          label="كلمة المرور الجديدة"
                          fullWidth
                          name='TheNewPassword_ChangePassword'
                          value={values.TheNewPassword_ChangePassword}
                          onBlur={handleBlur('TheNewPassword_ChangePassword')}
                          onChange={handleChange('TheNewPassword_ChangePassword')}
                          variant="outlined"
                          inputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: '#9FA1A3' } }}
                          style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr', marginBottom: '10px' }}
                          size="small"
                        />
                        <TextField
                          className='AccountEdit-Form-Textarea'
                          label="إعادة كلمة المرور الجديدة"
                          fullWidth
                          name='TheNewPassword_Confirm_ChangePassword'
                          value={values.TheNewPassword_Confirm_ChangePassword}
                          onBlur={handleBlur('TheNewPassword_Confirm_ChangePassword')}
                          onChange={handleChange('TheNewPassword_Confirm_ChangePassword')}
                          variant="outlined"
                          inputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: '#9FA1A3' } }}
                          style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px', direction: 'ltr', marginBottom: '10px' }}
                          size="small"
                        />
                        <div style={{ width: '66%', display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center' }}>
                          <Button className='AccountEdit-Form-Button' type='submit'
                            style={{ width: isNonMobile ? '60%' : '80%', color: 'white', borderRadius: '30px' }}
                            disabled={values.TheCurrentPassword_ChangePassword.length > 0
                              && values.TheNewPassword_ChangePassword.length > 0 && values.TheNewPassword_Confirm_ChangePassword.length > 0 ? false : true}
                          >
                            حفظ التغييرات
                          </Button>
                          <LazyLoadImage
                            alt='Profile_Loading'
                            effect="blur"
                            draggable={false}
                            src={Profile_Loading}
                            onContextMenu={(e) => e.preventDefault()}
                            style={{ width: '30px', height: '30px', display: ChangePassword_Loading ? 'flex' : 'none' }} />
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>

              </div>

            </div>

            {/** the 4th row */}

            <div className='AccountEdit-bx-Container'>

              <div className='AccountEdit-bx-Container-content'>
                <header style={{ paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
                  <h2 style={{
                    fontSize: '1.25rem',
                    color: darkMode === 'light' ? '#000000' : '#ffffff',
                    borderRadius: '0.2rem'
                  }}
                  >
                    <span style={{ color: '#ff434c' }}>|</span>
                    إدارة سجل المشاهدة / التحميل / التنبيهات
                  </h2>
                </header>

                <EditWDNHistory />

              </div>

            </div>

          </div>
        </div>
      </div>


    </div>
  )
}

export default AccountEdit


/*
 <div className='AccountEdit-bx-Container'>

              <div className='AccountEdit-bx-Container-content'>
                <header style={{ paddingRight: '30px', paddingTop: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%', padding: '0.5rem' }}>
                  <h2 style={{
                    fontSize: '1.25rem',
                    color: darkMode === 'light' ? '#000000' : '#ffffff',
                    borderRadius: '0.2rem'
                  }}
                  >
                    <span style={{ color: '#ff434c' }}>|</span>
                    العضوية المميزة
                    <span style={{ color: '#ff434c' }}> .... ( قريباً ) </span>
                  </h2>
                </header>
                <span
                  aria-multiline="true"
                  style={{
                    fontSize: '0.95rem',
                    color: darkMode === 'light' ? '#000000' : '#AFAEAE',
                    borderRadius: '0.2rem',
                    width: isNonMobile ? '33%' : '65%'
                  }}
                >
                  بإمكانك تفعيل عضويتك المميزة بوضع المفتاح في هذه الخانة والنقر على !REEDEM  وللمزيد من المعلومات عن العضوية المميزة بإمكانك زيارة
                  <Link
                    to={`${window.location.origin}/A2G_promo`}
                    target='_self'
                    rel="noopener noreferrer"
                    style={{ color: '#2E79CE', marginRight: '10px' }}>عرض الباقات</Link>
                </span>
                <div style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'right', alignItems: 'center', textAlign: 'center', width: '100%', padding: '0.5rem',
                }}>
                  <Formik onSubmit={Submit_Activate_A2G_promo}
                    initialValues={Activate_A2G_promo_initialValues}
                    validationSchema={Activate_A2G_promo_Schema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit} style={{ width: isNonMobile ? '55%' : '100%', flexDirection: isNonMobile ? null : 'column' }}>
                        <TextField
                          className='AccountEdit-Form-Textarea'
                          placeholder="XXXX-XXXX-XXXX"
                          fullWidth
                          name='ActivateCode'
                          value={values.ActivateCode}
                          onBlur={handleBlur('ActivateCode')}
                          onChange={handleChange('ActivateCode')}
                          variant="outlined"
                          inputProps={{ style: { color: 'white' } }}
                          InputLabelProps={{ style: { color: '#9FA1A3' } }}
                          style={{ width: isNonMobile ? '65%' : '80%', color: 'white', borderRadius: '30px' }}
                          size="small"
                        />
                        <Button
                          className='AccountEdit-Form-Button'
                          type='submit'
                          style={{ width: isNonMobile ? '25%' : '80%', color: 'white', borderRadius: '30px' }}
                          disabled={true}>!REEDEM</Button>
                      </form>
                    )}

                  </Formik>
                </div>

              </div>
            </div>
*/