import React, { useEffect, useState, useRef } from 'react'
import useMediaQuery from "@mui/material/useMediaQuery";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Slider from 'react-slick';
import arrow1 from "../../../assets/img/Others/arrow1.svg";
import arrow2 from "../../../assets/img/Others/arrow2.svg";
import { useAuth } from '../../../xFireBase/AuthContext';
import { Link } from 'react-router-dom';

const SearchAppsResults = ({ searchResult, searchInput }) => {

  const { CurrentLanguage, darkMode } = useAuth();
  const arrowRef = useRef();
  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(max-width: 1200px)" && "(min-width: 522px)");
  const isMobile = useMediaQuery("(max-width: 521px)")


  return (
    <div style={{
      width: '100%', height: searchResult?.length > 0 ? isMobile && searchResult?.length <= 2 ? '550px' : '350px' : '100%', flexDirection: 'column',
      display: 'flex', justifyContent: 'flex-start', alignItems: "center", textAlign: 'center', marginBottom: isMobile ? '85px' : '55px', transform: 'scale(0.9)'
    }}>
      <header style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', textAlign: 'right', width: '100%',
        direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
      }}>
        <h2 style={{
          fontSize: '1.25rem',
          color: darkMode === 'light' ? '#000000' : '#ffffff',
          borderRadius: '0.2rem'
        }}
        >
          <span style={{ color: '#ff434c' }}>|</span>{CurrentLanguage === 'EN' ? ' Apps :' : ' البرامج :'} {searchResult?.length}
        </h2>
        {searchResult?.length > 3 ?
          <div style={{ display: "flex", alignItems: "center", direction: CurrentLanguage === 'EN' ? 'rtl' : null }}>
            <button
              className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
              onClick={() => arrowRef.current.slickNext()}
            >
              <img
                loading="lazy"
                src={arrow2}
                alt="arrow2"
                onContextMenu={(e) => e.preventDefault()} />
            </button>
            <button
              className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
              onClick={() => arrowRef.current.slickPrev()}
            >
              <img
                loading="lazy"
                src={arrow1}
                alt="arrow1"
                onContextMenu={(e) => e.preventDefault()} />
            </button>
          </div>
          : null}
      </header>

      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {searchResult?.length > 2 ?
          <Slider
            ref={arrowRef}
            dots={false}
            draggable={true}
            swipe={true}
            arrows={false}
            className='Search-Result-Container-Slider'
            focusOnSelect={true}
            autoplay={true}
            autoplaySpeed={3000}
            slidesToShow={isNonMobile ? searchResult?.length > 3 ? 4 : 3 : isTablet ? 2 : isMobile ? 1 : 1}
            slidesToScroll={isNonMobile ? searchResult?.length > 3 ? 4 : 3 : isTablet ? 2 : isMobile ? 1 : 1}
          >
            {searchResult
              // make it so it won't show the same App twice
              .filter((AppsResult, index, self) => index === self.findIndex((t) => (t.app_Number === AppsResult.app_Number && t.app_Page === AppsResult.app_Page)))
              // sort it like this: show the apps that starts with the search input first, then the apps that includes the search input
              .sort((a, b) => {
                if (a.app_Name.toLowerCase().startsWith(searchInput.toLowerCase()) && b.app_Name.toLowerCase().startsWith(searchInput.toLowerCase())) {
                  return 0;
                }
                if (a.app_Name.toLowerCase().startsWith(searchInput.toLowerCase())) {
                  return -1;
                }
                if (b.app_Name.toLowerCase().startsWith(searchInput.toLowerCase())) {
                  return 1;
                }
                if (a.app_Name.toLowerCase().includes(searchInput.toLowerCase()) && b.app_Name.toLowerCase().includes(searchInput.toLowerCase())) {
                  return 0;
                }
                if (a.app_Name.toLowerCase().includes(searchInput.toLowerCase())) {
                  return -1;
                }
                if (b.app_Name.toLowerCase().includes(searchInput.toLowerCase())) {
                  return 1;
                }
                return 0;
              })
              .map((AppsResult, index) => (
                <div
                  key={index}
                  className={darkMode === 'light' ? 'games-card light' : 'games-card dark'}
                >
                  <Link
                    key={index}
                    rel='noreferrer'
                    to={`/A2G_apps/${AppsResult.app_Number}/${AppsResult.app_Page}`}
                    style={{ textDecoration: 'none', color: '#ffffff', cursor: 'pointer', maxWidth: '300px', height: 'auto' }}
                  >
                    <div className="games-imgbx">
                      <span className='RETO_watermark'
                        style={{
                          fontSize: isNonMobile ? '0.8rem' : '0.53rem',
                          color: 'white',
                          backgroundColor: 'rgba(0, 0, 0, 0.4)',
                          opacity: '0.65',
                          padding: '0.2rem',
                          textDecoration: 'none',
                          borderRadius: '0.2rem',
                          position: 'absolute',
                          top: isNonMobile
                            ? '0.2rem'
                            : isNonMobile === false && AppsResult.app_Picture[0].height > AppsResult.app_Picture[0].width
                              ? '1rem' : '0.1rem',
                          left: isNonMobile ? '0.15rem' : '0.4rem',
                          maxHeight: '183.11px',
                          maxWidth: '315px'
                        }}
                      >Arab2Games.com</span>
                      <LazyLoadImage
                        effect="blur"
                        draggable={false}
                        src={AppsResult?.app_Picture[0]}
                        alt='app_Picture'
                        style={{ width: '100%', height: '100%', maxHeight: '180px', maxWidth: '275px', justifyContent: 'center', alignItems: 'center' }}
                        onContextMenu={(e) => e.preventDefault()} />
                    </div>

                    <div>

                      <h4 style={{
                        fontSize: '0.95rem',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        textAlign: 'left',
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem',
                        color: darkMode === 'light' ? '#000000' : '#ffffff',
                      }}
                      >
                        {`${AppsResult.app_Name.length >= 35 ? AppsResult.app_Name.slice(0, 35) + '...' : AppsResult.app_Name}`}
                      </h4>

                      <div style={{
                        flexDirection: 'row',
                        display: 'flex',
                        color: darkMode === 'light' ? '#2C2C2C' : '#969595',
                        direction: 'ltr',
                        fontSize: '0.75rem',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        textAlign: 'right',
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem'
                      }}
                      >
                        <h6 style={{
                          fontSize: '0.79rem',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          marginLeft: '0.5rem',
                          marginTop: '2.5px'
                        }}
                        >
                          {`${AppsResult.app_Size}`}
                        </h6>
                        <h6 style={{
                          fontSize: '0.95rem',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          marginLeft: '0.5rem',
                          marginRight: '0.5rem'
                        }}
                        >
                          : بحجم
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </Slider>
          :
          searchResult?.length > 0 ?
            <div
              draggable={false}
              className='Search-Result-Container-Slider'
            >
              {searchResult
                // make it so it won't show the same App twice
                .filter((AppsResult, index, self) => index === self.findIndex((t) => (t.app_Number === AppsResult.app_Number && t.app_Page === AppsResult.app_Page)))
                // sort it like this: show the apps that starts with the search input first, then the apps that includes the search input
                .sort((a, b) => {
                  if (a.app_Name.toLowerCase().startsWith(searchInput.toLowerCase()) && b.app_Name.toLowerCase().startsWith(searchInput.toLowerCase())) {
                    return 0;
                  }
                  if (a.app_Name.toLowerCase().startsWith(searchInput.toLowerCase())) {
                    return -1;
                  }
                  if (b.app_Name.toLowerCase().startsWith(searchInput.toLowerCase())) {
                    return 1;
                  }
                  if (a.app_Name.toLowerCase().includes(searchInput.toLowerCase()) && b.app_Name.toLowerCase().includes(searchInput.toLowerCase())) {
                    return 0;
                  }
                  if (a.app_Name.toLowerCase().includes(searchInput.toLowerCase())) {
                    return -1;
                  }
                  if (b.app_Name.toLowerCase().includes(searchInput.toLowerCase())) {
                    return 1;
                  }
                  return 0;
                })
                .map((AppsResult, index) => (
                  <div
                    key={index}
                    style={{ margin: '1rem' }}
                  >
                    <Link
                      key={index}
                      rel='noreferrer'
                      to={`/A2G_apps/${AppsResult.app_Number}/${AppsResult.app_Page}`}
                      style={{ textDecoration: 'none', color: '#ffffff', cursor: 'pointer' }}
                      >
                        <div className="games-imgbx">
                          <span
                            style={{
                              fontSize: isNonMobile ? '0.8rem' : '0.53rem',
                              color: 'white',
                              backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              opacity: '0.65',
                              padding: '0.2rem',
                              textDecoration: 'none',
                              borderRadius: '0.2rem',
                              position: 'absolute',
                              top: isNonMobile
                                ? '0.5rem'
                                : isNonMobile === false && AppsResult.app_Picture[0].height > AppsResult.app_Picture[0].width
                                  ? '1rem' : '0.1rem',
                              left: isNonMobile ? '0.15rem' : '0.4rem',
                            }}
                          >Arab2Games.com</span>
                          <LazyLoadImage
                            effect="blur"
                            draggable={false}
                            src={AppsResult.app_Picture[0]}
                            style={{ borderRadius: '15px' }}
                            alt='app_Picture'
                            onContextMenu={(e) => e.preventDefault()} />
                        </div>

                        <div>

                          <h4 style={{
                            fontSize: '0.95rem',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            textAlign: 'left',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                            color: darkMode === 'light' ? '#000000' : '#ffffff',
                          }}
                          >
                            {`${AppsResult.app_Name.length >= 35 ? AppsResult.app_Name.slice(0, 35) + '...' : AppsResult.app_Name}`}
                          </h4>

                          <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
                            direction: 'ltr',
                            fontSize: '0.75rem',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            textAlign: 'right',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem'
                          }}>
                            <h6 style={{
                              fontSize: '0.79rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginLeft: '0.5rem',
                              marginTop: '2.5px',
                            }}
                            >
                              {`${AppsResult.app_Size}`}
                            </h6>
                            <h6 style={
                              { fontSize: '0.95rem', justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginLeft: '0.5rem', marginRight: '0.5rem' }
                            }>
                              : بحجم
                            </h6>
                          </div>
                        </div>
                    </Link>
                  </div>
                ))}
            </div>
            :
            null
        }
      </div>
    </div>
  )
}

export default SearchAppsResults