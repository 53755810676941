import React, { useRef } from "react";
import { Container, Col } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import arrow1 from "../assets/img/Others/arrow1.svg";
import arrow2 from "../assets/img/Others/arrow2.svg";
import AppsIcon from '../assets/img/icons/shop-store-icon.svg';
import BasketIcon from '../assets/img/icons/basket2-fill.svg';
import GamingIcon from '../assets/img/icons/gamepad-icon.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import { useAuth } from "../xFireBase/AuthContext";


export const MainScreenBanner = () => {

  const { CurrentLanguage } = useAuth();
  const arrowRef = useRef();

  const isNonMobile = useMediaQuery("(min-width:1200px)");
  const isTablet = useMediaQuery("(max-width:119px)" && "(min-width:769px)");
  const isTablet_Small = useMediaQuery("(max-width:768px)" && "(min-width:525px)");


  const CarouselItems = ([
    {
      id: 1,
      Title: 'الألعاب',
      EN_Title: 'Games',
      Picture: 'https://arab2games-files-images.vercel.app/main_images/MainScreen-GamesLogo-main.jpg',
      Icon: GamingIcon,
      LinkTo: '/A2G_games',
      TargetBlank: false,
      Card_ClassName: 'MainScreen-card-Red',
      Gradient_className: "MainScreenBanner-Gradient-Red",
      Titles_Gradient_className: "MainScreenBanner-Titles-Red"
    },
    {
      id: 2,
      Title: 'التطبيقات',
      EN_Title: 'Apps',
      Picture: 'https://arab2games-files-images.vercel.app/main_images/MainScreen-AppsLogo-main.gif',
      Icon: AppsIcon,
      LinkTo: '/A2G_apps',
      TargetBlank: false,
      Card_ClassName: 'MainScreen-card-PurpleBlue',
      Gradient_className: "MainScreenBanner-Gradient-PurpleBlue",
      Titles_Gradient_className: "MainScreenBanner-Titles-PurpleBlue"

    },
    /* {
        id: 3,
        Title: 'بوتات ديسكورد',
        EN_Title: 'Discord Bots',
        Picture: 'https://cdn.discordapp.com/attachments/1120600422369988649/1147074988126523423/discord-loader.gif',
        Icon: DiscordIcon,
        LinkTo: '/RETO_bots',
        TargetBlank: false,
        Card_ClassName: 'MainScreen-card-Blue',
        Gradient_className: "MainScreenBanner-Gradient-Blue",
        Titles_Gradient_className: "MainScreenBanner-Titles-Blue"
  
      },
    */
    /*{
      id: 4,
      Title: 'المتجر',
      EN_Title: 'Store',
      Picture: 'https://arab2games-files-images.vercel.app/main_images/MainScreen-StoreLogo-main.gif',
      Icon: BasketIcon,
      LinkTo: 'https://likereto.store/',
      TargetBlank: true,
      Card_ClassName: 'MainScreen-card-Blue',
      Gradient_className: "MainScreenBanner-Gradient-Blue",
      Titles_Gradient_className: "MainScreenBanner-Titles-Blue"

    },*/

  ]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };


  return (
    <section className="banner" style={{ marginTop: isNonMobile ? '180px' : isTablet ? '100px' : '50px', display: 'flex' }}>
      <Container>

        <Col xs={12} md={6} xl={7}>
          <div

            style={{
              textAlign: 'center', marginTop: '40px', alignItems: 'center', justifyContent: 'center',
              flexDirection: 'row', width: isNonMobile ? '170%' : isTablet ? '200%' : isTablet_Small ? '100%' : '100%',
              display: isNonMobile ? 'flex' : 'block'
            }}>
            {isNonMobile && CarouselItems.length > 4 ?
              <button
                className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                onClick={() => arrowRef.current.previous()}
              >
                <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
              </button>
              :
              !isNonMobile ?
                <div style={{
                  alignItems: "center", position: 'relative', zIndex: '1', marginBottom: '20px', flexDirection: 'row', display: 'flex',
                  marginLeft: '25px', justifyContent: '', width: '100%'
                }}>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowLeft wow fadeInLeft" : "BannerSlider-arrowLeftMobile wow fadeInLeft"}
                    onClick={() => arrowRef.current.previous()}
                  >
                    <img src={arrow1} alt="arrow1" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                  <button
                    className={isNonMobile ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                    onClick={() => arrowRef.current.next()}
                  >
                    <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
                  </button>
                </div>
                : null}

            <div className={isNonMobile || isTablet ? "MainScreenSlider-bx wow zoomIn" : "MainScreenSlider-bxMobile wow zoomIn"}>

              <Carousel ref={arrowRef} responsive={responsive} infinite={true}
                autoPlay={isNonMobile ? CarouselItems.length > 4 ? true : false : true}
                autoPlaySpeed={5000}
                arrows={false} removeArrowOnDeviceType={["tablet", "mobile"]}
                showDots={isNonMobile ? false : true} dotListClass="custom-dot-list-style" >
                {CarouselItems.map((item, index) => {
                  return (
                    <div
                      style={{ 
                        textDecoration: 'none', 
                        color: 'white', 
                        maxWidth: 'auto', 
                        maxHeight: 'auto'
                      }}
                      className={item.Card_ClassName}
                    >
                      <Link
                        to={item.LinkTo}
                        key={index}
                        target={item.TargetBlank ? '_blank' : null}
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: 'white', width: '100%', height: '100%' }}
                      >
                        <div
                          size={12}
                          xs={6}
                          sm={6}
                          md={6}
                          lg={4}
                          xl={3}
                          style={{ cursor: 'pointer', transition: 'all 0.3s ease-in-out' }}>
                          <div className="MainScreen-imgbx">
                            <LazyLoadImage
                              effect="blur"
                              draggable={false}
                              src={item.Picture}
                              alt={CurrentLanguage === 'EN' ? item.EN_Title : item.Title}
                              onContextMenu={(e) => e.preventDefault()}
                              style={{ width: '100%', height: '210px', borderRadius: '0.2rem' }} />
                          </div>

                          <div
                            style={{ transition: 'all 0.3s ease-in-out' }}
                            className={item.Gradient_className}>

                            <div className={item.Titles_Gradient_className}>
                              <h4

                                style={
                                  {
                                    fontSize: '20px', fontWeight: 'bold', justifyContent: 'center', alignItems: 'center', textAlign: 'center',
                                    backgroundColor: '#00000098', marginLeft: '0.5rem', marginRight: '0.5rem', marginBottom: '2rem',
                                    padding: '0.2rem', color: '#ffffff', opacity: '1', backgroundSize: 'cover', borderRadius: '0.2rem'
                                  }
                                }>
                                <LazyLoadImage
                                  effect="blur"
                                  draggable={false}
                                  src={item.Icon}
                                  alt='Icon'
                                  onContextMenu={(e) => e.preventDefault()}
                                  style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} />
                                {CurrentLanguage === 'EN' ?
                                  `${item.EN_Title.length >= 35 ? item.EN_Title.slice(0, 35) + '...' : item.EN_Title}`
                                  :
                                  `${item.Title.length >= 35 ? item.Title.slice(0, 35) + '...' : item.Title}`
                                }
                              </h4>
                            </div>

                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
                }
              </Carousel>
            </div>
            {isNonMobile && CarouselItems.length > 4 ?
              <button
                className={isNonMobile || isTablet ? "BannerSlider-arrowRight wow fadeInRight" : "BannerSlider-arrowRightMobile wow fadeInRight"}
                onClick={() => arrowRef.current.next()}
              >
                <img src={arrow2} alt="arrow2" onContextMenu={(e) => e.preventDefault()} />
              </button>
              : null}
          </div>
        </Col>
      </Container>
    </section>
  )
}
