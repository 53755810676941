import axios from "axios";

// app_Downloaded_Times_updater method
const app_Downloaded_Times_updater = async ({All_appData, User_IP_Address}) => {
    try {
        const updateData = {
            _id: All_appData._id,
            app_Name: All_appData.app_Name,
            app_Number: All_appData.app_Number,
            app_Page: All_appData.app_Page,
            User_IP_Address: User_IP_Address,
        }

        await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/app_watched_times_updater", updateData)
        .then(() => {
            return true
        })
        .catch((error) => {
            console.log("❌ app_DownloadedTimesUpdater ~ error : ", error.response.data.message)
            return false
        })
    }
    catch (error) {
        console.log("❌ app_DownloadedTimesUpdater ~ couldn't update the download times ~ error :   ", error)
        return false;
    }
};

export default app_Downloaded_Times_updater;