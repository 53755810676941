import axios from "axios";

// app_add_Comment method
const app_add_Comment = async ({appData, comment_values}) => {
    try {
        const current_app_Data = {
            _id: appData._id,
            app_Name: appData.app_Name,
            app_Number: appData.app_Number,
            app_Page: appData.app_Page,
        }


        const response = await axios.post("https://arab2games-apps-backend.vercel.app/api/arab2games/app_add_Comment", {
            current_app_Data: current_app_Data,
            comment_values: comment_values
        })
            .then((response) => {
                if (response.status === 200) {


                   return 'done';
                }
            })

        return response;
    }
    catch (error) {
        console.log("❌ app_add_Comments ~ couldn't add the app comment ~ error :   ", error)
        return false;
    }
};

export default app_add_Comment;