import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import { CurrentAppCard } from "../../components/HomeMiddle/CurrentAppCard";
import 'animate.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Preloader from "../../hooks/Others/PreLoader";
import Profile_Loading from '../../assets/img/icons/Profile_Loading.svg';
import Page_Helmet from "../../hooks/SEO/react_Helmet";
import { useAuth } from '../../xFireBase/AuthContext';
import ScrollToTop from '../../ScrollToTop';
import processDescription from '../../hooks/processDescription';

function CurrentAppPage() {

  const { ALL_Apps, CurrentLanguage, darkMode, loading, User_OS_Platform } = useAuth();

  const { app_Number, app_Page } = useParams();
  const [appData, setAppData] = useState(null);

  const [something_happened, setSomething_happened] = useState(false);

  const [timer_animation, setTimer_animation] = useState(0);

  useEffect(() => {
    if (!appData || appData.length <= 0) {
      setTimeout(() => {
        setSomething_happened(true);
      }, 6000);
    }
  }, []);


  const axiosFetchData = async () => {
    try {
      // go through ALL_Apps and get the app_Number && app_Page
      const appdata = await ALL_Apps?.filter(app => app.app_Page === app_Page);
      return appdata;

    }
    catch (error) {
      console.log('error')
    }
  };

  useEffect(() => {
    async function fetchData() {
      const result = await axiosFetchData();
      if (result) {
        setAppData(result);
        setTimer_animation(1);
      }
    }
    fetchData();
  }, [app_Number, app_Page]);

  if (!appData) {
    return <Preloader load={loading} darkMode={darkMode} />
  }

  return (
    <div className={timer_animation === 1 ? "animate__animated animate__fadeIn" : ''}>
      <Page_Helmet
        title={`Arab2Games | ${appData[0]?.app_Name || ''}`}
        type="article"
        description={appData[0]?.app_Description
          ? appData[0]?.app_Description.length > 550
            ? processDescription(appData[0]?.app_Description.slice(0, 550)).concat('...')
            : processDescription(appData[0]?.app_Description) : ''
        }
        image={appData[0]?.app_Picture[0] || ''}
        url={window.location.href}
        keywords={appData[0]?.app_Categories || ''}
      />
      <ScrollToTop />
      <section className="GamePageSection"
        style={{
          maxWidth: User_OS_Platform === 'Mobile' ? `${window.innerWidth}px` : null
        }}
      >
        <Container>
          <Row>
            <Col size={12}>
              <Row
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                {appData?.length > 0 ? (
                  appData?.map((doc, index) => {
                    return (
                      <Col xs={6} sm={6} md={6} lg={5} xl={12} key={index} onClick={() => { }}>
                        <CurrentAppCard key={index} All_appData={doc} app_Page={doc.app_Page} />
                      </Col>
                    );
                  })
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', alignItems: 'center' }}>
                    {something_happened === false ?
                      <LazyLoadImage
                        effect="blur"
                        alt="Profile_Loading"
                        draggable={false}
                        src={Profile_Loading}
                        onContextMenu={(e) => e.preventDefault()}
                        style={{ width: '30px', height: '30px', display: 'flex' }} />
                      :
                      null
                    }
                    {something_happened === false && appData.length <= 0 ?
                      <h1>{CurrentLanguage === 'EN'
                        ? 'Loading App Page'
                        : 'جاري تحميل صفحة البرنامج'}</h1>
                      :
                      something_happened === true && appData.length <= 0 ?
                        <h3>{CurrentLanguage === 'EN'
                          ? 'Something went wrong ❌'
                          : '❌ حدث خطأ ما'}
                        </h3>
                        :
                        null
                    }
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default CurrentAppPage;
