import axios from "axios";

// app_get_Comments method
const app_get_Comments = async (appData) => {
    try {
        const updateData = {
            _id: appData._id,
            app_Name: appData.app_Name,
            app_Number: appData.app_Number,
            app_Page: appData.app_Page,
        }

        const response = await axios.post("https://arab2games-apps-backend.vercel.app/api/arab2games/app_get_Comments", updateData)
            .then((response) => {
                if (response.status === 200) {

                    const fetchedComments = response.data.app_Comments_encryptedData;

                    return fetchedComments;
                }
            })

        return response;
    }
    catch (error) {
        console.log("❌ app_get_Comments ~ couldn't get the app comments ~ error :   ". error)
        return false;
    }
};

export default app_get_Comments;