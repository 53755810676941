import React, { Fragment } from 'react';
import { useAuth } from '../xFireBase/AuthContext';


// Function to process the description and replace URLs with links
const processDescription = ({ desc, sentFrom }) => {

    const { CurrentLanguage } = useAuth();

    // Split by spaces, newlines, or a combination of both
    const words = desc?.split(/(\s+|\n+)/);

    return words?.map((word, index) => {
        // Check if the word is a URL
        if (word?.startsWith('https://') || word?.startsWith('www.')) {
            return (
                <Fragment key={index}>
                    <a
                        onClick={() => {
                            window.open(word?.startsWith('www.')
                            ? `https://${word}`
                            : word, '_blank');
                            
                        }}
                        style={{
                            cursor: 'pointer',
                            // line under the link
                            textDecoration: 'underline',
                            // color of the link
                            color: '#2F8DF8',
                        }}
                        rel="noopener noreferrer"
                    >
                        {sentFrom === 'CurrentGameCard'
                            ?
                            CurrentLanguage === 'EN'
                                ? <h4 style={{ display: 'inline', color: '#2F8DF8' }}>{"Link " + ' \n'}</h4>
                                : <h4 style={{ display: 'inline', color: '#2F8DF8' }}>{"رابط " + ' \n'}</h4>
                            :
                            word 
                        }
                    </a>
                    {' '} {/* Add space after the link */}
                </Fragment>
            );
        }
        // Return the word as plain text with space or newline character intact
        return <Fragment key={index}>{word}</Fragment>;
    });
};

export default processDescription;