import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import arrow1 from '../../../../assets/img/Others/arrow1.svg';
import arrow2 from '../../../../assets/img/Others/arrow2.svg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAuth } from '../../../../xFireBase/AuthContext';
import Skeleton from 'react-loading-skeleton';

const App_MightLikeSection = ({ current_Name, current_Genres, show_everything, setShow_everything_False }) => {


  const { CurrentLanguage, darkMode, ALL_Games } = useAuth();

  const arrowRef = useRef();
  const isNonMobile = useMediaQuery('(min-width:1200px)');
  const isTablet = useMediaQuery('(min-width:522px) and (max-width:1199px)');
  const [mightLikeGames, setMightLikeGames] = useState([]);


  useEffect(() => {
    if (window.location.href.includes('/A2G_games')) {
      const RandomGames = ALL_Games
        .filter((game) => {
          const commonGenres = game.game_Genres.filter((genre) => current_Genres.includes(genre));
          return commonGenres.length >= 2 && game.game_Name !== current_Name;
        })
        .sort(() => Math.random() - 0.5)
        .slice(0, 10);

      setMightLikeGames(RandomGames);
    }
  }, [ALL_Games, current_Name, current_Genres]);

  return (
    <div className="Might-LikeGames">
      <header
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'right',
          width: '100%',
          direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
        }}
      >
        <h6
          className="Might-LikeGames-Title"
          style={{
            color: darkMode === 'light' ? '#000000' : '#ffffff',
            textAlign: CurrentLanguage === 'EN' ? 'left' : 'right',
            direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl',
            fontSize: CurrentLanguage === 'EN' ? '1.4rem' : null,
          }}
        >
          {CurrentLanguage === 'EN' ? 'You might Like :' : 'ربما يعجبك أيضاً :'}
        </h6>

        {isNonMobile ? null : (
          <div style={{ display: 'flex', alignItems: 'center', direction: CurrentLanguage === 'EN' ? 'rtl' : null }}>
            <button
              className={isNonMobile ? 'BannerSlider-arrowRight wow fadeInRight' : 'BannerSlider-arrowRightMobile wow fadeInRight'}
              onClick={() => arrowRef.current.slickNext()}
            >
              <img
                loading="lazy"
                draggable={false}
                src={arrow2}
                alt="arrow2"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
            <button
              className={isNonMobile ? 'BannerSlider-arrowLeft wow fadeInLeft' : 'BannerSlider-arrowLeftMobile wow fadeInLeft'}
              onClick={() => arrowRef.current.slickPrev()}
            >
              <img
                loading="lazy"
                draggable={false}
                src={arrow1}
                alt="arrow1"
                onContextMenu={(e) => e.preventDefault()}
              />
            </button>
          </div>
        )}
      </header>

      <div className="Might-LikeGames-Container">
        {mightLikeGames?.length >= 2 ? (
          <Slider
            dots={false}
            centerMode={true}
            draggable={true}
            swipe={true}
            slide="div"
            className="Might-LikeGames-Container-Slider"
            ref={isNonMobile ? null : arrowRef}
            arrows={isNonMobile ? true : false}
            centerPadding="0px"
            focusOnSelect={true}
            infinite={true}
            autoplay={true}
            autoplaySpeed={3000}
            slidesToShow={isNonMobile ? 4 : isTablet ? 2 : 1}
            slidesToScroll={isNonMobile ? 1 : isTablet ? 1 : 1}
          >
            {mightLikeGames
              .filter((game, index, self) => index === self.findIndex((t) => t.game_Number === game.game_Number && t.game_Page === game.game_Page))
              .sort((a, b) => {
                if (a.game_Name.toLowerCase().startsWith(current_Name.toLowerCase()) && b.game_Name.toLowerCase().startsWith(current_Name.toLowerCase())) {
                  return 0;
                }
                if (a.game_Name.toLowerCase().startsWith(current_Name.toLowerCase())) {
                  return -1;
                }
                if (b.game_Name.toLowerCase().startsWith(current_Name.toLowerCase())) {
                  return 1;
                }
                if (a.game_Name.toLowerCase().includes(current_Name.toLowerCase()) && b.game_Name.toLowerCase().includes(current_Name.toLowerCase())) {
                  return 0;
                }
                if (a.game_Name.toLowerCase().includes(current_Name.toLowerCase())) {
                  return -1;
                }
                if (b.game_Name.toLowerCase().includes(current_Name.toLowerCase())) {
                  return 1;
                }
                return 0;
              })
              .map((mightLikeGame) => (
                <div
                className={darkMode === 'light' ? 'games-card light' : 'games-card dark'}
                  key={mightLikeGame.game_Number}
                >
                  <a
                    rel="noreferrer"
                    href={`/A2G_games/${mightLikeGame.game_Number}/${mightLikeGame.game_Page}`}
                    style={{ textDecoration: 'none', color: '#ffffff', cursor: 'pointer' }}
                  >
                    <div size={12} xs={6} sm={6} md={6} lg={4} xl={3}>
                      <div className="Might-LikeGames-Container-Item-Image">
                        <span
                          className="RETO_watermark"
                          style={{
                            fontSize: isNonMobile ? '0.8rem' : '0.53rem',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            opacity: '0.65',
                            padding: '0.2rem',
                            textDecoration: 'none',
                            borderRadius: '0.2rem',
                            position: 'absolute',
                            top: isNonMobile ? '0.2rem' : isNonMobile === false && mightLikeGame.game_Picture[0].height > mightLikeGame.game_Picture[0].width ? '1rem' : '0.1rem',
                            left: isNonMobile ? '0.15rem' : '0.4rem',
                          }}
                        >
                          Arab2Games.com
                        </span>
                        {show_everything === false ?
                          <Skeleton
                            style={{
                              width: '100%', justifyContent: "center", alignItems: "center", display: "flex",
                              margin: "0 auto", transition: "all 0.5s ease",
                            }}
                            height={100}
                          />
                          :
                          <LazyLoadImage
                            effect="blur"
                            draggable={false}
                            src={mightLikeGame.game_Picture[0]}
                            alt="game_Picture"
                            onContextMenu={(e) => e.preventDefault()}
                          />}
                      </div>

                      <div>
                        <h4
                          style={{
                            color: darkMode === 'light' ? '#000000' : '#ffffff',
                            fontSize: '0.95rem',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            textAlign: 'left',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                          }}
                        >
                          {show_everything === false ?
                            <Skeleton
                              style={{
                                width: '100%', display: "flex",
                                transition: "all 0.5s ease",
                              }}
                              height={15}
                              width={100}
                            />
                            :
                            `${mightLikeGame.game_Name.length >= 35 ? mightLikeGame.game_Name.slice(0, 35) + '...' : mightLikeGame.game_Name}`}
                        </h4>

                        <div
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            color: darkMode === 'light' ? '#2C2C2C' : '#969595',
                            fontSize: '0.75rem',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            textAlign: 'right',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '0.79rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginLeft: '0.5rem',
                              marginTop: '2.5px',
                            }}
                          >
                            {show_everything === false ?
                              <Skeleton
                                style={{
                                  width: '100%', display: "flex",
                                  transition: "all 0.5s ease",
                                }}
                                height={15}
                                width={50}
                              />
                              :
                              `${mightLikeGame.game_Size}`}
                          </h6>
                          <h6
                            style={{
                              fontSize: '0.95rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginLeft: '0.5rem',
                              marginRight: '0.5rem',
                            }}
                          >
                            : بحجم
                          </h6>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
          </Slider>
        ) : mightLikeGames?.length <= 1 ? (
          <div draggable={false} className="Search-Result-Container-Slider">
            {mightLikeGames
              .filter((game, index, self) => index === self.findIndex((t) => t.game_Number === game.game_Number && t.game_Page === game.game_Page))
              .sort((a, b) => {
                if (a.game_Name.toLowerCase().startsWith(current_Name.toLowerCase()) && b.game_Name.toLowerCase().startsWith(current_Name.toLowerCase())) {
                  return 0;
                }
                if (a.game_Name.toLowerCase().startsWith(current_Name.toLowerCase())) {
                  return -1;
                }
                if (b.game_Name.toLowerCase().startsWith(current_Name.toLowerCase())) {
                  return 1;
                }
                if (a.game_Name.toLowerCase().includes(current_Name.toLowerCase()) && b.game_Name.toLowerCase().includes(current_Name.toLowerCase())) {
                  return 0;
                }
                if (a.game_Name.toLowerCase().includes(current_Name.toLowerCase())) {
                  return -1;
                }
                if (b.game_Name.toLowerCase().includes(current_Name.toLowerCase())) {
                  return 1;
                }
                return 0;
              })
              .map((mightLikeGame, index) => (
                <div key={index} style={{ margin: '1rem' }}>
                  <a
                    key={index}
                    rel="noreferrer"
                    href={`/A2G_games/${mightLikeGame.game_Number}/${mightLikeGame.game_Page}`}
                    style={{ textDecoration: 'none', color: '#ffffff', cursor: 'pointer' }}
                  >
                    <div className="Search-Result-Container-Item-card2">
                      <div className="Search-Result-Container-Item-Image">
                        <span
                          className="RETO_watermark"
                          style={{
                            fontSize: isNonMobile ? '0.8rem' : '0.53rem',
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            opacity: '0.65',
                            padding: '0.2rem',
                            textDecoration: 'none',
                            borderRadius: '0.2rem',
                            position: 'absolute',
                            top: isNonMobile ? '0.2rem' : isNonMobile === false && mightLikeGame.game_Picture[0].height > mightLikeGame.game_Picture[0].width ? '1rem' : '0.1rem',
                            left: isNonMobile ? '0.15rem' : '0.4rem',
                          }}
                        >
                          Arab2Games.com
                        </span>
                        <LazyLoadImage
                          effect="blur"
                          draggable={false}
                          src={mightLikeGame.game_Picture[0]}
                          alt="game_Picture"
                          onContextMenu={(e) => e.preventDefault()}
                        />
                      </div>

                      <div>
                        <h4
                          style={{
                            fontSize: '0.95rem',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            textAlign: 'left',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                          }}
                        >
                          {`${mightLikeGame.game_Name.length >= 35 ? mightLikeGame.game_Name.slice(0, 35) + '...' : mightLikeGame.game_Name}`}
                        </h4>

                        <div
                          style={{
                            flexDirection: 'row',
                            display: 'flex',
                            color: '#969595',
                            direction: 'ltr',
                            fontSize: '0.75rem',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            textAlign: 'right',
                            marginLeft: '0.5rem',
                            marginRight: '0.5rem',
                          }}
                        >
                          <h6
                            style={{
                              fontSize: '0.79rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginLeft: '0.5rem',
                              marginTop: '2.5px',
                            }}
                          >
                            {`${mightLikeGame.game_Size}`}
                          </h6>
                          <h6
                            style={{
                              fontSize: '0.95rem',
                              justifyContent: 'center',
                              alignItems: 'center',
                              textAlign: 'center',
                              marginLeft: '0.5rem',
                              marginRight: '0.5rem',
                            }}
                          >
                            {CurrentLanguage === 'EN' ? ': Size' : ': بحجم'}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
          </div>
        ) : null}
      </div>

    </div>
  );
};

export default App_MightLikeSection;
