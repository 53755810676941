import React, { useState } from 'react'
import { Box, Button, TextField, CircularProgress } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { firebase, database } from '../../../../xFireBase/Storage';
import {
    FaEnvelope, FaEye, FaEyeSlash, FaLock,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../xFireBase/AuthContext';

const LoginForm = () => {

    const { CurrentLanguage } = useAuth();
    const [passwordShown, setPasswordShown] = useState(false);

    const [submitting, setSubmitting] = useState(false);


    const LoginSubmit = async (values) => {
        setSubmitting(true);
        try {

            await firebase.auth().signInWithEmailAndPassword(values.User_Email, values.User_Password)

            await database.collection('users')
                .doc(values.User_Email)
                .update({
                    User_Status: 'متصل',
                    User_Last_Login: new Date().toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }),
                })
                .then(() => {
                    // go back to the previous page
                    window.history.back();
                })
        }
        catch (error) {
            CurrentLanguage === 'EN'
                ? toast.error("Error while logging in")
                : toast.error("حدث خطأ أثناء تسجيل الدخول")
        }
        finally {
            setSubmitting(false);
        }
    };

    const banChecking = async (ban_checker, ban_Type, ban_reason, ban_reason_en, ban_ExpirationDate, values) => {


        if (ban_checker === true) {
            toast.info(`${CurrentLanguage === 'EN' ? 'Checking' : 'جاري التحقق'}`, {
                toastId: 'BanMessage1',
            })
            // ban_ExpirationDate is like this : 10/25/2023, 12:00:00 AM. So we need to split it to get the date only
            const ban_ExpirationDate_Date = ban_ExpirationDate.split(',')[0];

            setTimeout(() => {
                const currentDate = new Date(); // Get the current date
                const expirationDate = new Date(ban_ExpirationDate_Date);

                if (expirationDate <= currentDate && ban_Type === 'temporary') {
                    // Ban expiration is in the past
                    toast.success(`
                ${CurrentLanguage === 'EN'
                            ? `You are no longer banned`
                            : `لقد تم رفع الحظر عنك ! سيتم تسجيل دخولك`}
                `, {
                        toastId: 'BanMessage3',
                    })

                    setTimeout(() => {
                        database.collection('users')
                            .doc(values.User_Email)
                            .get()
                            .then((doc) => {
                                database.collection('users')
                                    .doc(values.User_Email)
                                    .update({
                                        User_Ban_Status: false,
                                    })
                                    .then(() => {
                                        database.collection('users')
                                            .doc('@important')
                                            .collection('more-details')
                                            .doc('Banned_Users')
                                            .collection('Banned_List')
                                            .doc(doc.data().User_uid)
                                            .delete()
                                            .then(() => {
                                                LoginSubmit(values);
                                            })
                                    })
                            })
                    }, 500);
                }
                else if (expirationDate > currentDate) {
                    // Ban expiration is in the future
                    toast.error(`
               ${CurrentLanguage === 'EN'
                            ? `You are banned
                  ${ban_Type === 'permanent' ? 'permanently' : 'temporarily'}
                  because of | ${ban_reason_en} |
                  ${ban_Type === 'temporary' ? `until ${expirationDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }).split(',')[0]}` : ''}
                  Contact the administration on Discord if there is an error
              `
                            : `لقد تم حظرك 
                ${ban_Type === 'permanent' ? 'مؤبد' : ban_Type === 'temporary' ? 'مؤقتاً' : ','}
                بسبب | ${ban_reason} |
                ${ban_Type === 'temporary' ? `حتى ${expirationDate.toLocaleString('en-US', { timeZone: 'Asia/Riyadh' }).split(',')[0]}` : ''}
                تواصل مع الإدارة في الدسكورد في حال وجود خطأ
              `}
              `, {
                        toastId: 'BanMessage2',
                    })
                }
            }, 3800);
        }
        else {
            LoginSubmit(values);
        }
    };

    // ban checker function
    const banChecker = async (values) => {
        try {
            const doc = await database.collection('users').doc(values.User_Email).get();

            if (doc.exists && doc.data().User_Ban_Status === true) {
                const data = await database
                    .collection('users')
                    .doc('@important')
                    .collection('more-details')
                    .doc('Banned_Users')
                    .collection('Banned_List')
                    .doc(doc.data().User_uid)
                    .get();

                if (data.exists) { // Check if the data exists in Firestore
                    // Extract the data from Firestore
                    const {
                        User_Ban_Type,
                        User_Ban_Reason,
                        User_Ban_Reason_EN,
                        User_Ban_ExpirationDate,
                    } = data.data(); // Use data() to access the document data

                    // Call banChecking with the retrieved values
                    banChecking(
                        doc.data().User_Ban_Status,
                        User_Ban_Type,
                        User_Ban_Reason,
                        User_Ban_Reason_EN,
                        User_Ban_ExpirationDate,
                        values
                    );
                } else {
                    LoginSubmit(values);
                }
            } else {
                LoginSubmit(values);
            }

        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };




    const phoneRegExp =
        /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

    const Login_checkoutSchema = yup.object().shape({
        User_Email: yup.string()
            .email(`${CurrentLanguage === 'EN' ? 'Please enter a valid email' : 'الرجاء إدخال بريد إلكتروني صحيح'}`)
            .required(`${CurrentLanguage === 'EN' ? 'Please enter your email' : 'الرجاء إدخال البريد الإلكتروني'}`),

        User_Password: yup.string()
            .min(6, `${CurrentLanguage === 'EN' ? 'Password must be at least 6 characters' : 'يجب أن تتكون كلمة المرور من 6 أحرف على الأقل'}`)
            .required(`${CurrentLanguage === 'EN' ? 'Please enter your password' : 'الرجاء إدخال كلمة المرور'}`),

    });
    const Login_initialValues = {
        User_Email: "",
        User_Password: "",
    };


    return (
        <Box style={{ flexDirection: 'column', display: 'flex' }}>
            <Formik
                onSubmit={banChecker}
                initialValues={Login_initialValues}
                validationSchema={Login_checkoutSchema}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>

                            <div style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                                <div style={{
                                    width: '100%', flexDirection: 'column', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '45px'
                                }}>
                                    <div style={{
                                        width: '100%', flexDirection: 'row', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                        justifyContent: 'center'
                                    }}>

                                        <FaEnvelope style={{ color: '#FFFFFF65', fontSize: '20px', marginRight: '10px', width: '25px' }} />
                                        <div style={{
                                            backgroundColor: '#83818186', direction: 'ltr', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center",
                                            borderRadius: '15px', width: '100%', height: '100%', textAlign: 'center'
                                        }}>
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                placeholder='example@example.com'
                                                label={CurrentLanguage === 'EN' ? 'Email' : 'البريد الإلكتروني'}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Email}
                                                autoComplete='disabled'
                                                name="User_Email"
                                                error={!!touched.User_Email && !!errors.User_Email}
                                                sx={{ gridColumn: "span 4", input: { color: '#FFFFFF' }, }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'left',
                                                        direction: 'ltr',
                                                        color: '#FFFFFF',
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        textAlign: 'left',
                                                        direction: 'ltr',
                                                        color: values.User_Email
                                                            && touched.User_Email && errors.User_Email ? '#F14141' : values.User_Email ? '#71A3FF'
                                                            :
                                                            touched.User_Email && errors.User_Email ? '#F14141' : '#CFCFCF',
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {touched.User_Email && errors.User_Email ?
                                        <span style={{
                                            color: '#F14141', fontSize: '16px', marginTop: '85px', position: 'absolute',
                                            direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                        }}>
                                            * {errors.User_Email && touched.User_Email && errors.User_Email}
                                        </span>
                                        :
                                        null
                                    }

                                </div>

                                <div style={{
                                    width: '100%', flexDirection: 'column', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                    justifyContent: 'center', marginBottom: '20px'
                                }}>
                                    <div style={{
                                        width: '100%', flexDirection: 'row', display: 'flex', direction: 'ltr', textAlign: 'center', alignItems: "center",
                                        justifyContent: 'center', marginBottom: '10px'
                                    }}>
                                        <FaLock style={{ color: '#FFFFFF65', fontSize: '20px', marginRight: '10px', width: '25px' }} />
                                        <div style={{
                                            backgroundColor: '#83818186', direction: 'ltr', display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center",
                                            borderRadius: '15px', width: '100%', height: '100%', textAlign: 'center'
                                        }}>
                                            <TextField
                                                fullWidth
                                                style={{ borderRadius: '15px' }}
                                                variant="filled"
                                                type={passwordShown ? "text" : "password"}
                                                security={false}
                                                label={CurrentLanguage === 'EN' ? 'Password' : 'كلمة المرور'}
                                                autoComplete='new-password'
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.User_Password}
                                                name="User_Password"
                                                error={!!touched.User_Password && !!errors.User_Password}
                                                sx={{ gridColumn: "span 4", input: { color: '#FFFFFF' } }}
                                                inputProps={{
                                                    style: {
                                                        textAlign: 'left',
                                                        direction: 'ltr',
                                                        color: '#FFFFFF',
                                                    }
                                                }}
                                                InputLabelProps={{
                                                    style: {
                                                        textAlign: 'left',
                                                        direction: 'ltr',
                                                        color: values.User_Password
                                                            && touched.User_Password && errors.User_Password ? '#F14141' : values.User_Password ? '#71A3FF'
                                                            :
                                                            touched.User_Password && errors.User_Password ? '#F14141' : '#CFCFCF',
                                                    }
                                                }}
                                            />
                                            <a
                                                onClick={() => setPasswordShown(!passwordShown)}
                                                style={{ justifyContent: 'center', alignItems: 'center', fontSize: '20px', width: '40px' }}>
                                                {passwordShown ?
                                                    <FaEyeSlash style={{ color: '#000000B9', fontSize: '20px', width: '25px' }} />
                                                    :
                                                    <FaEye style={{ color: '#000000B9', fontSize: '20px', width: '25px' }} />
                                                }
                                            </a>
                                        </div>
                                    </div>

                                    {touched.User_Password && errors.User_Password ?
                                        <span style={{
                                            color: '#F14141', fontSize: '16px', marginTop: '75px', position: 'absolute',
                                            direction: CurrentLanguage === 'EN' ? 'ltr' : 'rtl'
                                        }}>
                                            * {errors.User_Password && touched.User_Password && errors.User_Password}
                                        </span>
                                        :
                                        null
                                    }

                                </div>

                            </div>
                            <div style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <Box display="flex" alignItems={"center"} justifyContent="center" flexDirection="column" marginTop={"15px"} >
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            submitting
                                            || !values.User_Email
                                            || !values.User_Password
                                            || touched.User_Email && errors.User_Email
                                            || touched.User_Password && errors.User_Password
                                        }
                                        style={{
                                            backgroundColor: !values.User_Email
                                                || !values.User_Password
                                                || touched.User_Email && errors.User_Email
                                                || touched.User_Password && errors.User_Password
                                                ? '#1976D22D' : '#1976d2',

                                            color: !values.User_Email
                                                || !values.User_Password
                                                || touched.User_Email && errors.User_Email
                                                || touched.User_Password && errors.User_Password
                                                ? '#FFFFFF5E' : '#FFFFFF',
                                        }}
                                    >
                                        {submitting
                                            ?
                                            // loading icon
                                            <CircularProgress
                                                size={20}
                                                style={{ color: 'white', marginRight: '5px' }}
                                            />
                                            :
                                            CurrentLanguage === 'EN' ? 'Login' : 'تسجيل الدخول'
                                        }
                                    </Button>
                                </Box>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </Box>
    );
};

export default LoginForm;